<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Update Calendar date">
            <validation-observer ref="updateDateValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Date"
                      label-for="date"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="date"
                        rules="required"
                      >
                        <div>
                          <b-form-datepicker
                            v-model="form.date"
                            :min="min"
                            :max="max"
                            locale="en"
                          />
                        </div>
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Status"
                      label-for="status"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="status"
                        rules="required"
                      >
                        <b-form-select
                          v-model="form.status"
                          :options="options"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Update
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const calendarModule = createNamespacedHelpers('calendar')

export default {
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // 15th two months prior
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth() - 2)
    minDate.setDate(15)
    // 15th in two months
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 2)
    maxDate.setDate(15)

    return {
      loader: false,
      form: {
        date: '',
        status: null,
      },
      options: [
        { value: null, text: 'Please select status', disabled: true },
        { value: 1, text: 'Enable' },
        { value: 0, text: 'Disable' },
      ],
      min: minDate,
      max: maxDate,
      currentData: null,
    }
  },
  async mounted() {
    this.loader = true
    await this.fetchData()
    this.loader = false
  },
  methods: {
    ...calendarModule.mapActions(['FETCH_SINGLE_CALENDAR_DATE', 'UPDATE_CALENDAR_DATE']),

    async fetchData() {
      try {
        this.loader = true
        this.currentData = await this.FETCH_SINGLE_CALENDAR_DATE(this.$route.params.id)
        this.form.date = this.currentData.date
        this.form.status = this.currentData.status
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    formatDate(date) {
      return date.replaceAll('-', '/')
    },

    onSubmit() {
      this.$refs.updateDateValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            const newForm = {
              status: this.form.status,
              date: this.formatDate(this.form.date),
            }
            const resp = await this.UPDATE_CALENDAR_DATE({ id: this.$route.params.id, data: newForm })
            if (resp) {
              this.$nextTick(() => {
                this.$refs.updateDateValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Date Updated!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'Date has been updated successfully!',
                },
              })
            }
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },
  },
}
</script>
